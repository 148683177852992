<template>
    <div class="pdf-main">
	    <div id="demo" class="pdf"></div>
        <i class="download-icon" @click="down"></i>
         <van-overlay :show="shareShow" class-name="shareover" @click="shareShow = false">
            <div class="share-box" >
                <img src="@/assets/img/share-arrow-icon.png" alt="">
                <div class="share-txt">
                    <p>{{$t("pe.shareoverlay")}}</p>
                    <p>{{$t("pe.shareoverlay2")}}</p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
  import Pdfh5 from "pdfh5";
  import download from "downloadjs";
import wxconfig from '@/utils/wxconfig.js';

  export default {
        name: 'App',
        data() {
            return {
                shareShow:false,
                pdfh5: null
            };
        },
        created(){
            try {
                if(window.navigator.userAgent.indexOf('micromessenger')!==-1){
                    wxconfig.init();
                    wxconfig.share({
                        title: this.$t("pe.Report"), // 分享标题
                        desc: this.$t("pe.name"), // 分享描述
                        link: window.location.href, // 分享链接
                    });
                }

            } catch (error) {
                // alert("error")
            }
        },
        mounted() {
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let that = this;
            //实例化
            this.pdfh5 = new Pdfh5("#demo", {
                pdfurl: that.$route.query.pdfUrl
            });
            //监听完成事件
            this.pdfh5.on("complete", function (status, msg, time) {
                toast.clear();
            })
        },
        methods:{
            async down(){
                // try {
                //     download(this.$route.query.pdfUrl)
                // } catch (error) {
                //     alert("error")
                // }
                    var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
                    if (ua.match(/MicroMessenger/i) == "micromessenger") {
                        //在微信中打开
                        // this.copyText(window.location.href);
                        this.shareShow = true;
                    }else{
                        if(this.isIos()){
                            window.location.href = this.$route.query.pdfUrl;
                        }else{
                            this.pdfh5.download(this.$route.query.pdfUrl,()=>{
                            })
                        }
                        return;
                    }
                    if (ua.match(/WeiBo/i) == "weibo") {
                            //在新浪微博客户端打开
                    }
                    if (ua.match(/QQ/i) == "qq") {
                            //在QQ空间打开
                    }
                    if (browser.versions.ios) {
                            //是否在IOS浏览器打开
                    }
                    if(browser.versions.android){
                            //是否在安卓浏览器打开
                    }

                    //否则就是PC浏览器打开
                    this.pdfh5.download(this.$route.query.pdfUrl,()=>{
                    })
                // const InstructionManualvv = await InstructionManual();
                // let blob = new Blob([InstructionManualvv.data], {type: 'application/pdf;charset=UTF-8'});
                // let fileName = this.$route.query.pdfUrl;
                // if (window.navigator.msSaveOrOpenBlob) {
                //     navigator.msSaveBlob(blob, fileName)
                // } else {
                //     var link = document.createElement('a');
                //     link.href = window.URL.createObjectURL(blob);
                //     link.download = fileName;
                //     link.click();
                //     window.URL.revokeObjectURL(link.href) //释放内存
                // }
            },
            isIos(){
                let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
                let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                if (isAndroid) {
                    // window.android.JSToNative(JSON.stringify(params))
                    return false;
                } else {
                    return true;
                    // window.webkit.messageHandlers.JSToNative.postMessage(params)
                    // JSToNative(JSON.stringify(params))
                }
            },
            copyText(txt) {
                var oInput = document.createElement('input');     //创建一个隐藏input
                oInput.value = txt;    //赋值
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                oInput.className = 'oInput';
                oInput.style.display='none';
                oInput.remove();
                this.$toast(this.$t("pe.copytips"))
            },
        }
  }
</script>

<style lang="scss">
	.pdf {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .viewerContainer{
        height: 100vh !important;

      width: 100%;
      .pinch-zoom-container{
          height: 100vh !important;
      }
      *{
        margin: 0 auto;
        max-width: 100%;
        // height:100vh !important;
      }
    }
	}
    .shareover{

    .share-box{
        float: right;
        width: max-content;
        margin: 2.5rem auto 0 auto;
        margin-right: 1.2rem;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        img{
            width: 2.9rem;
            height: 3.5rem;
        }
        .share-txt{
            float: right;
            height: 2.2rem;
            width: 8.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #F65947;
            border-radius: 1.1rem;
            margin-right: 1.25rem;
            position: relative;
            p{
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 0.88rem;
            }
        }
    }
}
    .download-icon{
        position: fixed;
        background: url("~@/assets/img/download-icon.png") no-repeat;
        width: 1rem;
        height: 1rem;
        top: 0.6rem;
        right: 0.6rem;
        background-size: 100% 100%;
    }
</style>
